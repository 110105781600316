import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import MUIAlert from '@material-ui/lab/Alert';
import Alert from '../util/Alerts';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { createRefund } from '../api/Refunds';
import Orders from './RefundOrders';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '360px',
  },
  dateDay: {
    paddingLeft: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewRefundModal({ children, orderPackage }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();
  const [orders, setOrders] = React.useState();
  const [generateCredit, setGenerateCredit] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = e => {
    e.preventDefault();

    if (typeof(generateCredit) === 'undefined' && orderPackage.usedCredits === 0) {
      return;
    }

    if (orders.length < 1) {
      return;
    } else {
      setLoading(true);
      setError(null);
    }

    const { fetcher } = createRefund({
      generateCredit: generateCredit === "true" || orderPackage.usedCredits > 0,
      packageId: orderPackage.id,
      ordersIds: orders
    });

    fetcher.then(result => {
      handleClose();
      setLoading(false);
      setNotice('Reembolso criado com sucesso');
      window.location.reload();
    }).catch(err => {
      setError('Erro ao salvar');
      setLoading(false);
      console.log(JSON.stringify(err));
    });
  };

  return (
    <>
      <Alert message={notice} onClose={() => { setNotice(null) }} />

      <Button
        onClick={handleClickOpen}
        type="submit"
        variant="contained"
        color="secondary"
      >
        Novo reembolso
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <form onSubmit={onSubmit} className={classes.form}>
          <DialogContent>
            { error && <MUIAlert severity="error">{error}</MUIAlert> }

            <Orders
              orders={orderPackage.orders.filter(o => o.cancelled)}
              onChange={oids => setOrders(oids)}
            />

            {
              orderPackage.usedCredits === 0
              &&
              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Modalidade"
                onChange={e => setGenerateCredit(e.target.value)}
                SelectProps={{
                  native: true,
                }}
              >
                <option>-- Selecione</option>
                <option value={true}>Gerar crédito</option>
                <option value={false}>Devolver pagamento</option>
              </TextField>
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Cancelar
            </Button>
            <Button color="secondary" variant="contained" type="submit" disabled={loading}>
              Reembolsar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
