import Api from './Api';

export function signinAgent(params) {
  const exec = `
    mutation signinAgent(
      $email: String!
      $password: String!
    ) {
      signinAgent(
        email: $email
        password: $password
      ) {
        id
        token
        email
      }
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}
