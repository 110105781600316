import React from 'react';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { showOrder, cancelOrder } from '../api/Orders';
import { showPackage } from '../api/Packages';
import Empty from '../util/Empty';
import Currency from '../util/Currency';

export default function Order() {
  let { orderId } = useParams();
  const [order, setOrder] = React.useState();
  const [orderPackage, setOrderPackage] = React.useState();
  const [error, setError] = React.useState();

  const loadOrder = () => {
    const { fetcher } = showOrder(orderId);

    fetcher.then(result => {
      setOrder(result.order);
    }).catch(err => {
      console.log(err);
      setError(true);
    });
  };

  const handleCancel = () => {
    if (window.confirm("Tem certeza?")) {
      const { fetcher } = cancelOrder(orderId);

      fetcher.then(result => {
        if (result.cancelOrder.cancelled) {
          loadOrder();
        }
      }).catch(err => {
        console.log(err);
      });
    }
  };

  React.useEffect(() => {
    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  React.useEffect(() => {
    if (order && order.packageId) {
      const { fetcher } = showPackage(order.packageId);

      fetcher.then(result => {
        setOrderPackage(result.package);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [order]);

  if (error) return <Empty>Nenhuma reserva encontrada</Empty>;
  if (!order) return <CircularProgress />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell>
                { orderPackage
                  ?
                    <>
                      <b>Comprador</b>:
                      &nbsp;

                      {
                        orderPackage.user
                        ?
                        <Link href={`/users/${orderPackage.user.id}`}>
                          {orderPackage.user.fullname || '<sem nome>'}
                        </Link>
                        :
                        <>-- Sem usuário</>
                      }
                    </>
                  :
                    <CircularProgress />
                }
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>
                <Link href={`/packages/${order.packageId}`}>
                  Ver pacote completo
                </Link>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Código</b>: {order.id}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Checkin</b>:
                {' '}
                <Moment format="DD/MM/YYYY">
                  {order.checkin}
                </Moment>
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Total</b>: <Currency value={order.total} /></TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Usuários</b>: {order.totalAmount}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Completo</b>: {order.complete ? 'Sim' : 'Não'}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Status</b>:{' '}
                {order.status === 'started' && <Chip label="Iniciado" color="default" size="small" variant="outlined" />}
                {order.status === 'approved' && <Chip label="Incompleto" color="primary" size="small" variant="outlined" />}
                {order.status === 'complete' && <Chip label="A confirmar" color="secondary" size="small" variant="outlined" />}
                {order.status === 'confirmed' && <Chip label="Confirmado" color="primary" size="small" />}
                {order.status === 'accomplished' && <Chip label="Realizado" color="default" size="small" />}
                {order.status === 'cancelled' && <Chip label="Cancelado" color="secondary" size="small" />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Produto</b>: {order.product.name} ({order.product.id})</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Parceiro</b>: {order.product.supplier.name}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Sessão</b>: {order.section.name}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                { order.options.map(option => (
                  <div key={option.option.id}>
                    <b>{option.option.name}</b>: {option.amount} x <Currency value={option.price} /> ({option.option.details})
                  </div>
                )) }
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Usuários:</b>
                { order.users.map(user => (
                  <div key={user.name}>
                    {user.name} | {user.idType.toUpperCase()}: {user.idNumber}
                  </div>
                )) }
              </TableCell>
            </TableRow>

            {
              order.gatheringOption
              &&
              <>
                <TableRow hover>
                  <TableCell>
                    <b>Serviço de busca:</b>
                    {' '}
                    {order.gatheringOption === 'gathering' && 'Busca em hospedagem'}
                    {order.gatheringOption === 'meeting_point' && 'Ponto de encontro'}
                  </TableCell>
                </TableRow>

                <TableRow hover>
                  <TableCell>
                    <b>Local de hospedagem:</b> {order.gatheringLocation || 'Não informado'}
                  </TableCell>
                </TableRow>
              </>
            }

            {
              order.transferFlightNumber
              &&
              <TableRow hover>
                <TableCell>
                  <b>Número do voo:</b> {order.transferFlightCompany}
                  {' '}
                  {order.transferFlightNumber}
                </TableCell>
              </TableRow>
            }

            {
              order.userComments
              &&
              <TableRow hover>
                <TableCell>
                  <b>Observações:</b> {order.userComments}
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      {
        order.transfer
        &&
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow hover>
                  <TableCell><b>Origem</b>: {order.transfer.locationFrom.name}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell><b>Destino</b>: {order.transfer.locationTo.name}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <b>Data</b>:
                    {' '}
                    <Moment format="DD/MM/YYYY">
                      {order.transfer.date}
                    </Moment>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <b>Hora</b>:
                    {' '}
                    {order.transfer.time}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
        </>
      }

      {
        (order.approved && !order.cancelled)
        &&
        <Button
          onClick={handleCancel}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Cancelar reserva
        </Button>
      }
    </>
  )
}
