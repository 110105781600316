import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Empty from '../util/Empty';
import Currency from '../util/Currency';
import User from './UserContext';
import { listPackages } from '../api/Packages';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  newLink: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  }
}));

export default function OrdersPage() {
  const { user } = React.useContext(User);
  const classes = useStyles();
  const [orders, setOrders] = React.useState();
  const [loading, setLoading] = React.useState();

  const loadOrders = () => {
    setLoading(true);

    const { fetcher } = listPackages();

    fetcher.then(result => {
      setLoading(false);
      setOrders(result.packages);
    });
  };

  React.useEffect(() => {
    loadOrders();
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <h1>Pacotes gerados</h1>
        </Grid>

        <Grid item xs={4} className={classes.newLink}>
          <Button
            href={`https://guiapass.com/packages/new?a=${user.token}`}
            target="_blank"
            type="submit"
            variant="contained"
            color="secondary"
          >
            Criar novo link
          </Button>
        </Grid>
      </Grid>

      {
        orders
        ?
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>1º Checkin</TableCell>
                    <TableCell>Últ. Checkin</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orders.map(order => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={order.id}
                    >
                      <TableCell component="th" scope="row">
                        <Link href={`/packages/${order.id}`}>
                          <Moment format="DD/MM/YYYY HH:mm">
                            {order.createdAt}
                          </Moment>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Moment format="DD/MM/YYYY">
                          {order.firstCheckin}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <Moment format="DD/MM/YYYY">
                          {order.lastCheckin}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        {
                          order.user
                          ?
                          <Link href={`/users/${order.user.id}`}>
                            {order.user.fullname}
                          </Link>
                          :
                          <>-- Sem usuário</>
                        }
                      </TableCell>
                      <TableCell>
                        <Currency value={order.total} />
                      </TableCell>
                      <TableCell align="right">
                        {order.status === 'new' && <Chip label="Novo" color="default" size="small" variant="outlined" />}
                        {order.status === 'started' && <Chip label="Iniciado" color="primary" size="small" variant="outlined" />}
                        {order.status === 'loggedin' && <Chip label="Usuário logado" color="primary" size="small" variant="outlined" />}
                        {order.status === 'registered' && <Chip label="Usuário registrado" color="primary" size="small" variant="outlined" />}
                        {order.status === 'payment' && <Chip label="Pagamento selecionado" color="primary" size="small" variant="outlined" />}
                        {order.status === 'approved' && <Chip label="Pagamento aprovado" color="primary" size="small" />}
                        {order.status === 'expired' && <Chip label="Expirado" color="secondary" size="small" />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        :
          <Empty>Nenhum pacote gerado.</Empty>
      }
    </div>
  );
}
