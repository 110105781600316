import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { advisedUsers } from '../api/Users';
import Empty from '../util/Empty';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

export default function AdvisedPage() {
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = React.useState();
  const [loading, setLoading] = React.useState();

  React.useEffect(() => {
    const { fetcher } = advisedUsers();

    fetcher.then(result => {
      setLoading(false);
      setUsers(result.advisedUsers);
    });
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <div className={classes.root}>
      <h1>Agenciados</h1>
      {
        users
        ?
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>
                    <TableCell>CPF</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Cidade</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(users || []).map(user => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={user.id}
                    >
                      <TableCell component="th" scope="row">
                        <Link href={`/users/${user.id}`}>{user.fullname}</Link>
                      </TableCell>
                      <TableCell>{user.cpf}</TableCell>

                      <TableCell>
                        <Link href={`https://wa.me/55${user.phone}`} target="_blank">
                          {user.phone}
                        </Link>
                      </TableCell>

                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.addressCity} / {user.addressState}</TableCell>

                      <TableCell align="right">
                        <Button
                          href={`/orders?userId=${user.id}`}
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Reservas
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        :
          <Empty>Digite um termo de busca para começar.</Empty>
      }
    </div>
  );
}
