import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { showPackage, approvePackage, assignPackage } from '../api/Packages';
import Empty from '../util/Empty';
import Alert from '../util/Alerts';
import Currency from '../util/Currency';
import NewRefund from './NewRefund';
import User from './UserContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { isValidEmail } from '../util/validation';

export default function Order() {
  const { user } = React.useContext(User);
  let { packageId } = useParams();
  const [orderPackage, setOrderPackage] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [notice, setNotice] = React.useState();
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [assignEmail, setAssignEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  const loadOrder = () => {
    setLoading(true);
    setError(null);
    const { fetcher } = showPackage(packageId);

    fetcher.then(result => {
      if (result.package) {
        setOrderPackage(result.package);
        setError(null); // Clear any existing error
      } else {
        setError('Pacote não encontrado');
      }
    }).catch(err => {
      console.error(err);
      setError('Erro ao carregar o pacote');
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleApprovePackage = () => {
    if(!window.confirm('Deseja realmente aprovar o pacote?')) return;

    const { fetcher } = approvePackage(packageId);

    fetcher.then(result => {
      loadOrder();
    }).catch(err => {
      window.alert(JSON.stringify(err));
    });
  };

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    setNotice('Link copiado');
    e.target.focus();
  };

  const handleAssignAgent = () => {
    if (!assignEmail.trim()) {
      setEmailError('Email é obrigatório');
      return;
    }
    if (!isValidEmail(assignEmail)) {
      setEmailError('Email inválido');
      return;
    }

    setEmailError('');
    const { fetcher } = assignPackage(packageId, assignEmail);

    fetcher.then(result => {
      if (result.assignPackage && result.assignPackage.id) {
        setNotice(orderPackage.agent ? 'Atribuição de agente alterada com sucesso' : 'Agente atribuído com sucesso');
        loadOrder();
      } else {
        setError('Falha ao atribuir/alterar agente');
      }
    }).catch(err => {
      console.error(err);
      setError('Erro ao atribuir/alterar agente');
    });

    setOpenAssignDialog(false);
    setAssignEmail('');
  };

  React.useEffect(() => {
    if (packageId) {
      loadOrder();
    }
  }, [packageId]);

  if (loading) return <CircularProgress />;
  if (error) return <Empty>{error}</Empty>;
  if (!orderPackage) return <Empty>Nenhum pacote encontrado</Empty>;

  return (
    <>
      <Alert message={notice} onClose={() => { setNotice(null) }} />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>

            <TableRow hover>
              <TableCell>
                <b>Agente</b>:
                &nbsp;
                { orderPackage.agent?.email || '-- Sem agente' }
                {user?.admin && (
                  <Button
                    onClick={() => setOpenAssignDialog(true)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: '10px' }}
                  >
                    {orderPackage.agent ? 'Mudar atribuição' : 'Atribuir'}
                  </Button>
                )}
              </TableCell>
            </TableRow>

            {
              (orderPackage.status !== 'approved')
              &&
              <TableRow>
                <TableCell>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="standard-adornment-password">Link compartilhável</InputLabel>
                    <OutlinedInput
                      inputRef={textAreaRef}
                      readOnly
                      multiline
                      labelWidth={185}
                      value={`https://guiapass.com/packages/${orderPackage.id}/shared`}
                      onFocus={() => { textAreaRef.current.select(); }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={copyToClipboard}
                            onMouseDown={copyToClipboard}
                          >
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            }

            <TableRow hover>
              <TableCell>
                <b>Criado</b>:
                {' '}
                <Moment format="DD/MM/YYYY HH:mm">
                  {orderPackage.createdAt}
                </Moment>
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>
                <b>Comprador</b>:
                &nbsp;

                {
                  orderPackage.user
                  ?
                  <Link href={`/users/${orderPackage.user.id}`}>
                    {orderPackage.user.fullname || '<sem nome>'}
                  </Link>
                  :
                  <>-- Sem usuário</>
                }
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>
                <b>Total</b>: <Currency value={orderPackage.total} />
              </TableCell>
            </TableRow>

            {
              orderPackage.usedCredits > 0
              &&
              <>
                <TableRow hover>
                  <TableCell>
                    <b>Créditos usados</b>: <Currency value={orderPackage.usedCredits} />
                  </TableCell>
                </TableRow>

                <TableRow hover>
                  <TableCell>
                    <b>Total final (sem juros)</b>: <Currency value={orderPackage.totalIncreasedDiscounted} />
                  </TableCell>
                </TableRow>
              </>
            }

            {
              orderPackage.rejected
              &&
              <TableRow hover>
                <TableCell>
                  <b>Pagamento rejeitado</b>:
                  {' '}
                  <Chip label={orderPackage.rejectCode} color="secondary" size="small" />
                </TableCell>
              </TableRow>
            }

            {
              orderPackage.payment
              &&
              <>
                <TableRow hover>
                  <TableCell>
                    <b>Método de pagamento</b>: {orderPackage.payment.paymentMethod}
                    {' '}
                    {orderPackage.payment.paid && <Chip label="Pago" color="primary" size="small" />}
                    {orderPackage.payment.cancelled && <Chip label="Reprovado" color="secondary" size="small" />}
                    {(orderPackage.payment.analysis && !orderPackage.payment.paid && !orderPackage.payment.cancelled) && <Chip label="Análise" color="secondary" size="small" variant="outlined" />}
                    {(!orderPackage.payment.analysis && !orderPackage.payment.paid && !orderPackage.payment.cancelled) && <Chip label="Pendente" size="small" />}
                  </TableCell>
                </TableRow>

                {
                  orderPackage.payment?.installment
                  &&
                  <TableRow hover>
                    <TableCell>
                      <b>Parcelas</b>: {orderPackage.payment?.installment}x
                    </TableCell>
                  </TableRow>
                }

                {
                  orderPackage.payment?.totalPaid > orderPackage.totalIncreasedDiscounted
                  &&
                  <TableRow hover>
                    <TableCell>
                      <b>Total pago (com juros)</b>: <Currency value={orderPackage.payment.totalPaid} />
                    </TableCell>
                  </TableRow>
                }
              </>
            }

            {
              orderPackage.totalRefunded > 0
              &&
              <TableRow hover>
                <TableCell>
                  <b>Reembolsado</b>: <Currency value={orderPackage.totalRefunded} />
                </TableCell>
              </TableRow>
            }

            <TableRow hover>
              <TableCell>
                <b>Status</b>:
                {' '}
                {orderPackage.status === 'new' && <Chip label="Novo" color="default" size="small" variant="outlined" />}
                {orderPackage.status === 'started' && <Chip label="Iniciado" color="primary" size="small" variant="outlined" />}
                {orderPackage.status === 'loggedin' && <Chip label="Usuário logado" color="primary" size="small" variant="outlined" />}
                {orderPackage.status === 'registered' && <Chip label="Usuário registrado" color="primary" size="small" variant="outlined" />}
                {orderPackage.status === 'payment' && <Chip label="Pagamento selecionado" color="primary" size="small" variant="outlined" />}
                {orderPackage.status === 'approved' && <Chip label="Pacote aprovado" color="primary" size="small" />}
                {orderPackage.status === 'expired' && <Chip label="Expirado" color="secondary" size="small" />}
              </TableCell>
            </TableRow>

            {
              (orderPackage.status !== 'approved' && user?.admin)
              &&
              <TableRow hover>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleApprovePackage}
                  >
                    Aprovar pagamento
                  </Button>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Reserva</TableCell>
              <TableCell>Checkin</TableCell>
              <TableCell>Serviço</TableCell>
              <TableCell>Agendamento</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orderPackage.orders.map(order => (
              <TableRow
                hover
                tabIndex={-1}
                key={order.id}
              >
                <TableCell component="th" scope="row">
                  <Link href={`/orders/${order.id}`}>{order.id}</Link>
                </TableCell>
                <TableCell>
                  <Moment format="DD/MM/YYYY">
                    {order.checkin}
                  </Moment>
                </TableCell>
                <TableCell>{order.product.name} ({order.product.id})</TableCell>
                <TableCell>
                  {order.complete && <Chip label="Completo" color="primary" size="small" />}
                  {!order.complete && <Chip label="Incompleto" color="default" size="small" />}
                </TableCell>
                <TableCell>
                  {order.cancelled && <Chip label="Cancelado" color="secondary" size="small" />}
                  {!order.cancelled && <Chip label="Ativo" color="primary" size="small" />}
                </TableCell>
                <TableCell align="right">
                  <Currency value={order.total} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      {
        orderPackage.refunds.length > 0
        &&
        <TableContainer component={Paper}>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Reembolsos</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Juros</TableCell>
                <TableCell>Créditos</TableCell>
                <TableCell>Reservas</TableCell>
                <TableCell>Opção</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orderPackage.refunds.map(refund => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={refund.id}
                >
                  <TableCell component="th" scope="row">
                    <Moment format="DD/MM/YYYY - HH:mm">
                      {refund.createdAt}
                    </Moment>
                  </TableCell>
                  <TableCell>
                    <Currency value={refund.amount} />
                  </TableCell>
                  <TableCell>
                    <Currency value={refund.interest} />
                  </TableCell>
                  <TableCell>
                    <Currency value={refund.credits} />
                  </TableCell>
                  <TableCell>{refund.ordersIds.join(" ") || '-'}</TableCell>
                  <TableCell>
                    { refund.generateCredit ? 'Crédito' : 'Devolvido' }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

      <br />

      {
        (orderPackage.status !== 'approved')
        &&
        <Button
          href={`https://guiapass.com/packages/${orderPackage.id}/shared`}
          target="_blank"
          type="submit"
          variant="contained"
          color="secondary"
        >
          Modificar itens do pacote
        </Button>
      }

      {
        (orderPackage.refundable > 0 && orderPackage.orders.filter(o => o.cancelled).length > 0)
        &&
        <NewRefund orderPackage={orderPackage} />
      }

      <Dialog
        open={openAssignDialog}
        onClose={() => setOpenAssignDialog(false)}
        aria-labelledby="assign-dialog-title"
        aria-describedby="assign-dialog-description"
      >
        <DialogTitle id="assign-dialog-title">
          {orderPackage.agent ? 'Mudar atribuição de agente' : 'Atribuir agente'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {orderPackage.agent
              ? 'Insira o email do novo agente para mudar a atribuição do pacote.'
              : 'Insira o email do agente para atribuir o pacote.'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="assign-email"
            label="Email do agente"
            type="email"
            fullWidth
            required
            error={!!emailError}
            helperText={emailError}
            value={assignEmail}
            onChange={(e) => {
              setAssignEmail(e.target.value);
              setEmailError('');
            }}
            onBlur={() => {
              if (!assignEmail.trim()) {
                setEmailError('Email é obrigatório');
              } else if (!isValidEmail(assignEmail)) {
                setEmailError('Email inválido');
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleAssignAgent}
            color="primary"
            disabled={!assignEmail.trim() || !!emailError}
          >
            {orderPackage.agent ? 'Mudar atribuição' : 'Atribuir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}