import Api from './Api';

export function listUsers(params) {
  const exec = `
    query(
      $query: String!
    ) {
      users (
        query: $query
      ) {
        id
        email
        cpf
        fullname
        firstName
        lastName
        photo
        phone
        addressCity
        addressState
        verified
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showUser(id) {
  const exec = `
    query($id: ID!) {
      user(id: $id) {
        id
        email
        token
        cpf
        fullname
        firstName
        lastName
        photo
        phone
        addressCity
        addressState
        availableCredits
        refundableCredits
        verified
        agent {
          email
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function adviseUser(id) {
  const exec = `
    mutation($id: ID!) {
      adviseUser(id: $id)
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function advisedUsers() {
  const exec = `
    {
      advisedUsers {
        id
        email
        fullname
        phone
        cpf
        addressCity
        addressState
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec)
  };
}

export function verifyUser(id) {
  const exec = `
    mutation($id: ID!) {
      verifyUser(id: $id)
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}
