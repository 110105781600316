import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import MUIAlert from '@material-ui/lab/Alert';
import Alert from '../util/Alerts';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { createLead } from '../api/Leads';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '360px',
  },
  dateDay: {
    paddingLeft: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewLeadModal({ children }) {
  const classes = useStyles();
  const { register, handleSubmit, setValue } = useForm();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = data => {
    let checkin = null;

    if (data.checkin !== '') {
      checkin = data.checkinMonth + '-' + data.checkin;
    }

    setLoading(true);
    setError(null);

    const { fetcher } = createLead({
      ...data,
      checkinMonth: checkin ? undefined : data.checkinMonth,
      checkin: checkin ? checkin : undefined
    });

    fetcher.then(result => {
      handleClose();
      setLoading(false);
      setNotice('Lead salvo com sucesso');
    }).catch(err => {
      setError('Erro ao salvar');
      setLoading(false);
      console.log(JSON.stringify(err));
    });
  };

  return (
    <>
      <Alert message={notice} onClose={() => { setNotice(null) }} />

      <Button
        onClick={handleClickOpen}
        type="submit"
        variant="contained"
        color="secondary"
      >
        Novo lead
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <DialogContent>
            { error && <MUIAlert severity="error">{error}</MUIAlert> }

            <TextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Destino"
              onChange={e => setValue("destinationId", e.target.value)}
              SelectProps={{
                native: true,
                inputProps: { ref: register({ required: true }), name: 'destinationId' }
              }}
            >
              <option>-- Selecione</option>
              <option value="O3R51W1P">Gramado e Canela</option>
              <option value="XM8OGWP4">Rio de Janeiro</option>
              <option value="NXW1JRDQ">Foz do Iguaçu</option>
            </TextField>

            <TextField
              inputRef={register({ required: true })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Nome (e sobrenome se possível)"
              name="name"
              autoComplete="off"
            />

            <TextField
              inputRef={register({ required: true })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="WhatsApp"
              name="phone"
              type="tel"
              autoComplete="off"
            />

            <TextField
              inputRef={register}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              type="email"
              autoComplete="off"
            />

            <Grid container>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Checkin (mês)"
                  select
                  onChange={e => setValue("checkinMonth", e.target.value)}
                  SelectProps={{
                    native: true,
                    inputProps: { ref: register, name: 'checkinMonth' }
                  }}
                >
                  <option> -- Selecione</option>
                  {
                    Array(12).fill().map((i, index) => (
                      <option
                        key={index.toString()}
                        value={moment().add(index, 'M').format('YYYY-MM')}
                      >
                        {moment().add(index, 'M').format('MM/YYYY')}
                      </option>
                    ))
                  }
                </TextField>
              </Grid>

              <Grid item xs={6} className={classes.dateDay}>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Checkin (dia)"
                  name="checkin"
                  type="tel"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 2,
                    minLength: 2
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              inputRef={register}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Comentários"
              name="comments"
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Cancelar
            </Button>
            <Button color="secondary" variant="contained" type="submit" disabled={loading}>
              Salvar lead
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
