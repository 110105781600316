import Api from './Api';

export function createRefund(params) {
  const exec = `
    mutation(
      $packageId: ID!
      $ordersIds: [ID!]
      $generateCredit: Boolean!
    ) {
      createRefund(
        packageId: $packageId
        ordersIds: $ordersIds
        generateCredit: $generateCredit
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
