import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import theme from './components/ThemeContext';

import Login from './components/Login';
import Auth from './components/Auth';
import User from './components/UserContext';

import Users from './components/Users';
import Advised from './components/Advised';
import Orders from './components/Orders';
import Order from './components/Order';
import Leads from './components/Leads';
import Lead from './components/Lead';
import UserPage from './components/User';
import Packages from './components/Packages';
import Package from './components/Package';
import Report from './components/Report';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Moment from 'react-moment';
import 'moment/locale/pt-br';
Moment.globalLocale = 'pt-BR';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  login: {
    margin: '0 auto',
  }
}));

function Layout() {
  const { user } = React.useContext(User);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!user) return <></>;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Router>
          <CssBaseline />
          {
            (user && user.token)
            &&
            <>
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <Icon>menu</Icon>
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    GuiaPass CRM
                  </Typography>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <Icon>chevron_left</Icon> : <Icon>chevron_right</Icon>}
                  </IconButton>
                </div>
                <Divider />

                <List>
                  <ListItem button component={Link} to="/users" onClick={handleDrawerClose}>
                    <ListItemIcon><Icon>person</Icon></ListItemIcon>
                    <ListItemText primary="Clientes" />
                  </ListItem>

                  <ListItem button component={Link} to="/advised" onClick={handleDrawerClose}>
                    <ListItemIcon><Icon>how_to_reg</Icon></ListItemIcon>
                    <ListItemText primary="Agenciados" />
                  </ListItem>

                  <ListItem button component={Link} to="/leads" onClick={handleDrawerClose}>
                    <ListItemIcon><Icon>phone</Icon></ListItemIcon>
                    <ListItemText primary="Leads" />
                  </ListItem>

                  <ListItem button component={Link} to="/packages" onClick={handleDrawerClose}>
                    <ListItemIcon><Icon>shopping_cart</Icon></ListItemIcon>
                    <ListItemText primary="Link pacote" />
                  </ListItem>

                  <ListItem button component={Link} to="/report" onClick={handleDrawerClose}>
                    <ListItemIcon><Icon>trending_up</Icon></ListItemIcon>
                    <ListItemText primary="Relatório" />
                  </ListItem>
                </List>
              </Drawer>
            </>
          }
          <main
            className={clsx((user && user.token) ? classes.content : classes.login, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Switch>
              <PrivateRoute path="/users/:userId">
                <UserPage />
              </PrivateRoute>

              <PrivateRoute path="/users">
                <Users />
              </PrivateRoute>

              <PrivateRoute path="/advised">
                <Advised />
              </PrivateRoute>

              <PrivateRoute path="/leads/:leadId">
                <Lead />
              </PrivateRoute>

              <PrivateRoute path="/leads">
                <Leads />
              </PrivateRoute>

              <PrivateRoute path="/orders/:orderId">
                <Order />
              </PrivateRoute>

              <PrivateRoute path="/orders">
                <Orders />
              </PrivateRoute>

              <PrivateRoute path="/packages/:packageId">
                <Package />
              </PrivateRoute>

              <PrivateRoute path="/packages">
                <Packages />
              </PrivateRoute>

              <PrivateRoute path="/report">
                <Report />
              </PrivateRoute>

              <Route path="/">
                <Login />
              </Route>
            </Switch>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const { user } = React.useContext(User);

  if (!user) return <></>;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (user && user.token) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  return (
    <Auth>
      <Layout />
    </Auth>
  );
}
