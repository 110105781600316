import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: '50px 0',
  },
}));

export default function Empty({ children }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {children}
    </Paper>
  )
}
