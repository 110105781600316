import Api from './Api';

export function listLeads(params) {
  const exec = `
    query(
      $status: String
    ) {
      leads(
        status: $status
      ) {
        id
        attended
        createdAt
        name
        phone
        email
        destination {
          name
        }
        checkin
        checkout
        checkinMonth
        product
        paxRange
        profile
        interests
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showLead(id) {
  const exec = `
    query($id: ID!) {
      lead(id: $id) {
        id
        name
        phone
        email
        destination {
          name
        }
        checkin
        checkout
        checkinMonth
        product
        paxRange
        profile
        interests
        comments
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function attendLead(id) {
  const exec = `
    mutation($id: ID!) {
      attendLead(id: $id) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function createLead(params) {
  const exec = `
    mutation(
      $destinationId: ID!
      $name: String!
      $phone: String!
      $email: String
      $checkin: String
      $checkinMonth: String
      $comments: String
    ) {
      createLead(
        destinationId: $destinationId
        checkin: $checkin
        checkinMonth: $checkinMonth
        name: $name
        phone: $phone
        email: $email
        comments: $comments
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
