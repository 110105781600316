import React from 'react';
import Context from './UserContext';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const Provider = ({ children }) => {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const stored = window.localStorage.getItem('userAgent');
    const parsedStored = stored ? JSON.parse(stored) : null;
    const parsedJwt = parsedStored ? parseJwt(parsedStored.token) : null;

    setUser(parsedJwt ? { ...parsedStored, ...parsedJwt } : {});
  }, []);

  return <Context.Provider value={{ user }}>{children}</Context.Provider>;
};

export default Provider;
