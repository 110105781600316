import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(
  process.env.NODE_ENV === 'production'
  ? 'https://api.store.guiapass.com/graphql'
  : 'http://localhost:5200/graphql'
);

export default function Api(options = {}) {
  if (options.authorized === true) {
    if (typeof window === 'undefined') return false;
    const user = JSON.parse(window.localStorage.getItem('userAgent'));
    if (!user || !user.token) return false;
    client.setHeader('authorization', `Bearer ${user.token}`);
  }

   // Add error handling to the client
   const wrappedClient = {
    ...client,
    request: async (...args) => {
      try {
        return await client.request(...args);
      } catch (error) {
        if (error.response?.status === 401) {
          // Handle 401 error - e.g., clear local storage and redirect to login
          window.localStorage.removeItem('userAgent');
          window.location.href = '/login'; // Adjust the login path as needed
        }
        throw error; // Re-throw the error to be handled by the caller
      }
    }
  };

  return wrappedClient;
}
