import NumberFormat from 'react-number-format';

export const toCurrency = (value) => {
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return `${amount}`;
};

export default function Currency({ value, display = 'text' }) {
  return (
    <NumberFormat
      value={value}
      format={toCurrency}
      displayType={display}
    />
  );
};
