import Api from './Api';

export function createCreditRefund(params) {
  const exec = `
    mutation(
      $userId: ID!
      $amount: Int!
    ) {
      createCreditRefund(
        userId: $userId
        amount: $amount
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
