import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import User from './UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

export default function ReportPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);

  if (!user) return <></>;

  // const params = {
  //   "ds6.agente": user.email
  // };

  // const paramsAsString = JSON.stringify(params);
  // const encodedParams = encodeURIComponent(paramsAsString);

  const encodedParams = `%7B"df9":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${user.email.replace('@', '%2540')}"%7D`;

  return (
    <div className={classes.root}>
      <h1>Relatório</h1>

      <Paper className={classes.paper}>
        <iframe
          width="1100"
          height="800"
          src={`https://datastudio.google.com/embed/reporting/f7e07308-2efd-4c9c-95e9-52ddbea431f1/page/uIqjC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Paper>
    </div>
  );
}
