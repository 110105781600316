import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import Empty from '../util/Empty';
import Currency from '../util/Currency';
import NewLead from './NewLead';
import { listLeads, attendLead } from '../api/Leads';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  newLink: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  }
}));

export default function LeadsPage() {
  const classes = useStyles();
  const [leads, setLeads] = React.useState();
  const [loading, setLoading] = React.useState();
  const [tab, setTab] = React.useState("waiting");
  const history = useHistory();

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleAttend = (id) => {
    if (window.confirm("Tem certeza?")) {
      setLoading(true);

      const { fetcher } = attendLead(id);

      fetcher.then(result => {
        history.push(`/leads/${result.attendLead.id}`);
      });
    }
  };

  const loadOrders = () => {
    setLoading(true);

    const { fetcher } = listLeads({
      status: tab
    });

    fetcher.then(result => {
      setLoading(false);
      setLeads(result.leads);
    });
  };

  React.useEffect(() => {
    loadOrders();
  }, [tab]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <h1>Leads</h1>
        </Grid>

        <Grid item xs={4} className={classes.newLink}>
          <NewLead />
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTab}
        >
          <Tab label="Aguardando" value="waiting" />
          <Tab label="Atendidos" value="attended" />
        </Tabs>

        <Divider />

        {
          (leads && leads.length === 0)
          &&
          <Empty>Nenhum lead no momento.</Empty>
        }

        {
          (loading || !leads)
          ?
          <CircularProgress />
          :
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableBody>
                {leads.map(lead => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={lead.id}
                  >
                    <TableCell component="th" scope="row">
                      <Moment fromNow>
                        {lead.createdAt}
                      </Moment>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {lead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {lead.destination.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>Checkin:</b>
                      {' '}
                      <Moment fromNow>
                        {lead.checkin || `${lead.checkinMonth}-01`}
                      </Moment>
                    </TableCell>
                    <TableCell align="right">
                      {
                        !lead.attended
                        ?
                        <Button
                          onClick={() => handleAttend(lead.id)}
                          size="small"
                          type="submit"
                          variant="contained"
                          color="secondary"
                        >
                          Atender
                        </Button>
                        :
                        <Button
                          href={`/leads/${lead.id}`}
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Visualizar
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Paper>
    </div>
  );
}
