import React from 'react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import { useParams } from "react-router-dom";
import { showUser, adviseUser, verifyUser } from '../api/Users';
import { createCreditRefund } from '../api/CreditRefunds';
import Alert from '../util/Alerts';
import Currency from '../util/Currency';

export default function UserPage() {
  let { userId } = useParams();
  const [user, setUser] = React.useState();
  const [notice, setNotice] = React.useState();

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    setNotice('Link copiado');
    e.target.focus();
  };

  const handleAdviseUser = () => {
    if (userId && window.confirm('Tem certeza?')) {
      const { fetcher } = adviseUser(userId);

      fetcher.then(result => {
        window.location.reload();
      }).catch(err => {
        console.log(err);
      });
    }
  };

  const handleVerifyUser = () => {
    if (userId && window.confirm('Tem certeza?')) {
      const { fetcher } = verifyUser(userId);

      fetcher.then(result => {
        window.location.reload();
      }).catch(err => {
        console.log(err);
      });
    }
  };

  const refundCredits = () => {
    if (window.confirm('Tem certeza?')) {
      const { fetcher } = createCreditRefund({
        userId: user.id,
        amount: user.refundableCredits
      });

      fetcher.then(result => {
        window.location.reload();
      }).catch(err => {
        console.log(err);
      });
    }
  };

  React.useEffect(() => {
    if (userId) {
      const { fetcher } = showUser(userId);

      fetcher.then(result => {
        setUser(result.user);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [userId]);

  if (!user) return <CircularProgress />;

  return (
    <>
      <Alert message={notice} onClose={() => { setNotice(null) }} />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell>
                <b>Agente</b>:
                {' '}
                { user.agent?.email || '-- Sem agente' }
                {' '}

                {
                  (false && !user.agent)
                  &&
                  <Button
                    onClick={handleAdviseUser}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Agenciar
                  </Button>
                }

              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Nome</b>: {user.fullname}
                { user.verified && <Icon color="primary">verified</Icon> }
                {' '}
                {
                  !user.verified
                  &&
                  <Button
                    onClick={handleVerifyUser}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Definir como verificado
                  </Button>
                }
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>CPF</b>: {user.cpf}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Email</b>: {user.email}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Telefone</b>:
                {' '}
                <Link href={`https://wa.me/55${user.phone}`} target="_blank">
                  {user.phone}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Créditos disponíveis</b>: <Currency value={user.availableCredits} />
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Créditos reembolsáveis</b>: <Currency value={user.refundableCredits} />
                {' '}
                {
                  user.refundableCredits > 0
                  &&
                  <Button onClick={refundCredits} color="secondary" size="small">
                    Devolver
                  </Button>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="standard-adornment-password">Login</InputLabel>
                  <OutlinedInput
                    inputRef={textAreaRef}
                    readOnly
                    multiline
                    labelWidth={60}
                    value={`https://guiapass.com?u=${user.token}`}
                    onFocus={() => { textAreaRef.current.select(); }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={copyToClipboard}
                          onMouseDown={copyToClipboard}
                        >
                          <Icon>file_copy</Icon>
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <p>
                    <b>Atenção:</b> acesse o link para login na conta do usuário somente em navegação anônima.
                  </p>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <Button
        href={`/orders?userId=${user.id}`}
        type="submit"
        variant="contained"
        color="primary"
      >
        Reservas
      </Button>
    </>
  )
}
